<template>

  <div class="accountM"
       v-loading.fullscreen.lock="fullscreenLoading">
    <el-dialog title="添加账号"
               v-model="isShowAdd"
               width="60%">
      <div>
        <el-form :model="addAccountData"
                 ref="addAccountData"
                 label-position='top'
                 class="AddUserRuleForm">

          <el-form-item label="用户名"
                        prop="userName"
                        :rules="[
      { required: true, message: '用户名不能为空'},
      { max:10,min:2, message: '2-10个字符'}
    ]">
            <el-input v-model="addAccountData.userName"
                      placeholder="请输入用户名"></el-input>
          </el-form-item>

          <el-form-item label="用户手机号码"
                        prop="userMobile"
                        :rules="[
      { required: true, message: '手机号码不能为空'},
    ]">
            <el-input type="number"
                      v-model="addAccountData.userMobile"
                      placeholder="请输入用户的手机号码"></el-input>
          </el-form-item>

          <el-form-item label="用户电子邮箱"
                        prop="userEmaile">
            <el-input v-model="addAccountData.userEmaile"
                      placeholder="请输入用户的地址邮箱（可选）"></el-input>
          </el-form-item>

          <el-form-item label="用户密码"
                        prop="parssWord"
                        :rules="[
      { required: true, message: '密码不能为空'},
      { max:20,min:6, message: '6-20个字符'}
    ]">
            <el-input v-model="addAccountData.parssWord"
                      placeholder="请输入用户设置的密码"></el-input>
          </el-form-item>

          <el-form-item label="用户权限"
                        prop="jurisdiction"
                        :rules="[
      { required: true, message: '权限不能为空'}
    ]">
            <el-select v-model="addAccountData.jurisdiction"
                       placeholder="请选择该用户的权限">
              <el-option v-for="item in qxList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>

      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowAdd = false">取 消</el-button>
          <el-button type="primary"
                     @click="addAccountUser('addAccountData')">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="管理账号"
               v-model="isShowM"
               width="60%">
      <div>
        <el-form :model="accountMData"
                 ref="accountMData"
                 label-position='top'
                 class="AddUserRuleForm">

          <el-form-item label="用户名"
                        prop="name"
                        :rules="[
      { required: true, message: '用户名不能为空'},
      { max:10,min:2, message: '2-10个字符'}
    ]">
            <el-input v-model="accountMData.name"
                      placeholder="请输入用户名"></el-input>
          </el-form-item>

          <el-form-item label="用户手机号码"
                        prop="mobile"
                        :rules="[
      { required: true, message: '手机号码不能为空'},
    ]">
            <el-input v-model="accountMData.mobile"
                      placeholder="请输入用户的手机号码"></el-input>
          </el-form-item>

          <el-form-item label="用户电子邮箱"
                        prop="email">
            <el-input v-model="accountMData.email"
                      placeholder="请输入用户的地址邮箱（可选）"></el-input>
          </el-form-item>

          <el-form-item label="用户密码"
                        prop="parssWord">
            <el-input v-model="accountMData.parssWord"
                      placeholder="如果需要修改，可填写"></el-input>
          </el-form-item>

          <el-form-item label="用户权限"
                        prop="roleid"
                        :rules="[
      { required: true, message: '权限不能为空'}
    ]">
            <el-select v-model="accountMData.roleid"
                       placeholder="请选择该用户的权限">
              <el-option v-for="item in qxList"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>

      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isShowM = false">取 消</el-button>
          <el-button type="primary"
                     @click="modificationUser('accountMData')">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="accountM_top">
      <el-button size='mini'
                 type='success'
                 @click="addAccount">添加账号</el-button>
    </div>
    <div class="accountM_tab">
      <el-table :data="tableData"
                style="width: 100%">
        <el-table-column prop="uid"
                         label="UID"
                         width="180">
        </el-table-column>
        <el-table-column prop="name"
                         label="用户名"
                         width="180">
        </el-table-column>
        <el-table-column label="用户邮箱"
                         width="180">
          <template #default='scope'>
            <span v-if="scope.row.email!=1">{{scope.row.email}}</span>
            <span v-else
                  style="color:#999">未设置</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile"
                         label="手机号码"
                         width="180">
        </el-table-column>
        <el-table-column prop="rolename"
                         label="权限"
                         width="180">
          <template #header>
            <el-dropdown>
              <span class="el-dropdown-link">
                权限<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :class="activeQx_2==''?'activeStyle':''"
                                    @click="inqueryJurisdiction()">全部</el-dropdown-item>
                  <el-dropdown-item v-for="item in qxList"
                                    :class="activeQx_2==item.id?'activeStyle':''"
                                    @click="inqueryJurisdiction(item.id)"
                                    :key=item.id>{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>

        <el-table-column align="right">
          <template #header>
            <el-input v-model="userNameSearch"
                      size="mini"
                      :placeholder="userNameSearch_active==''?'输入用户名搜索':'目前搜索：'+userNameSearch_active"
                      @keydown.enter="search_user" />
          </template>
          <template #default="scope">
            <el-button size="mini"
                       @click="getMAccountData(scope.row)">修改信息</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="removerUser(scope.row.uid)">删除账号</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background
                     :hide-on-single-page="true"
                     layout="prev, pager, next"
                     :total="pageN">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { postAccunt, postAddAccunt, postRemoveUser } from '@/request/api'
export default {
  name: 'accountM',
  data () {
    return {
      isShowM: false,
      // --------管理账号数据--------
      accountMData: {},
      // --------添加账号数据--------
      addAccountData: {
        userName: '',
        userMobile: '',
        userEmaile: '',
        parssWord: '',
        jurisdiction: ''
      },
      // --------是否显示添加账号--------
      isShowAdd: false,
      // --------加载动画--------
      fullscreenLoading: false,
      // --------查询仓库人员权限--------
      activeQx: '',
      activeQx_2: '',
      // --------权限查询--------
      qxList: [],
      // --------用户名搜索--------
      userNameSearch: '',
      userNameSearch_active: '',
      // --------员工数据--------
      tableData: [],
      //--------创始人ID--------
      uid: '',
      //--------仓库ID--------
      wid: '',
      //--------账号权限--------
      userQX: {},
      //--------页码--------
      page: 1,
      pageN: 0,
    }
  },
  methods: {
    //   --------搜索用户--------
    search_user () {
      this.userNameSearch_active = this.userNameSearch
      this.accountList(this.userNameSearch)
      this.userNameSearch = ''
    },
    //   --------删除用户--------
    removerUser (uid) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await postRemoveUser({
          ckid: this.wid,
          uid
        })
        if (res.errno == 0) {
          console.log(res);
        }
        console.log(res);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },
    //   --------修改用户--------
    modificationUser (formName) {
      this.fullscreenLoading = true
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await postAddAccunt({
            ckid: this.wid,
            uid: this.accountMData.id,
            name: this.accountMData.name,
            mobile: this.accountMData.mobile,
            roleid: this.accountMData.roleid,
            email: this.accountMData.email,
            password: this.accountMData.parssWord,
          })
          if (res.errno == 0) {
            this.$message.success(res.message)
            setTimeout(() => {
              //   this.$router.go(0)
            }, 1000);
          } else {
            this.$message.error(res.message)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
      this.fullscreenLoading = false
    },
    //   --------获取管理账号数据--------
    getMAccountData (data) {
      console.log(data);
      this.accountMData = data
      if (this.accountMData.email == 1) {
        this.accountMData.email = ''
      }
      this.accountMData.parssWord = ''
      this.isShowM = true
    },
    //   --------添加用户--------
    addAccountUser (formName) {
      this.fullscreenLoading = true
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await postAddAccunt({
            ckid: this.wid,
            csrenid: this.uid,
            name: this.addAccountData.userName,
            mobile: this.addAccountData.userMobile,
            roleid: this.addAccountData.jurisdiction,
            email: this.addAccountData.userEmaile,
            password: this.addAccountData.password,
          })
          if (res.errno == 0) {
            this.$message.success(res.message)
            setTimeout(() => {
              this.$router.go(0)
            }, 1000);
          } else {
            this.$message.error(res.message)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
      this.fullscreenLoading = false

    },
    //   --------显示添加账号--------
    addAccount () {
      this.isShowAdd = true
    },
    //   --------查询权限用户--------
    inqueryJurisdiction (id) {
      this.activeQx = id
      this.page = 1
      this.accountList()
    },
    //   --------获取会员权限--------
    async getMember () {
      this.fullscreenLoading = true;
      let res = await postAccunt({
        pd: 'getrole',
        ckid: this.wid
      })
      if (res.errno == 0) {
        this.qxList = res.rolelist
        if (this.userQX.role != 'admin') {
          delete this.qxList[4]
        }
      } else {
        this.$message.error(res.message)
      }
      this.fullscreenLoading = false;
    },
    //   --------获取仓库成员列表--------
    async accountList (username) {
      this.fullscreenLoading = true;
      let res = await postAccunt({
        pd: 'list',
        ckid: this.wid,
        page: this.page,
        roleid: this.activeQx,
        name: username
      })
      if (res.errno == 0) {
        this.tableData = res.userlist
        this.pageN = parseInt(res.total, 10)
        this.activeQx_2 = this.activeQx || ''
        this.getMember()

      } else {
        this.$message.error(res.message)
      }
      this.fullscreenLoading = false;

    }
  },
  async created () {

    this.userQX = JSON.parse(localStorage.getItem('tuangyin_ck_userinfo'))
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    //  --------- 
    if (cklist) {
      if (wid) {
        this.uid = wid.uid
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
        this.uid = cklist.cklist[0].uid
      }
    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', JSON.stringify(this.$store.state.warehouse))
      if (wid) {
        this.wid = wid.id
        this.uid = wid.uid
      } else {
        this.wid = cklist.cklist[0].id
        this.uid = cklist.cklist[0].uid
      }
    }
    this.accountList()
  }
}
</script>

<style lang='scss'>
.activeStyle {
  color: #4c90fd !important;
}
.accountM {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .AddUserRuleForm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-form-item {
      width: 45%;
    }
  }

  .accountM_top {
    width: 90%;
    flex: 1;
    max-height: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .accountM_tab {
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .el-pagination {
      margin-top: 20px;
    }
    .el-dropdown-link {
      color: #999;
    }
  }
}
</style>